(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react-router-dom"), require("react"));
	else if(typeof define === 'function' && define.amd)
		define(["react-router-dom", "react"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("react-router-dom"), require("react")) : factory(root["react-router-dom"], root["react"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(this, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__) {
return 